<template>
    <main>
        <page-header-compact>Formulario de registro de pruebas rápidas</page-header-compact>
        <div class="container-fluid">
            <div class="card card-icon mb-2 mt-2">
                <div class="row no-gutters">
                    <div class="col-auto card-icon-aside bg-light">
                        <i class="fas fa-user fa-fw font-weight-bolder"></i>
                    </div>
                    <div class="col">
                        <div class="card-body">
                            <div class="form-row">
                                <label class="small p-0 col-form-label col-2 font-weight-bolder">
                                    No. Identificación
                                    <small class="text-danger">*</small>
                                </label>
                                <div class="col-10">
                                    <usuario-field v-model="usuario" :validator="$v.usuario"></usuario-field>
                                    <span class="text-danger" v-if="!$v.usuario.required && $v.usuario.$dirty">
                                        Este campo es requerido
                                    </span>
                                </div>
                            </div>
                            <div class="form-row mt-2 mb-0">
                                <table class="table table-sm mb-0">
                                    <tr>
                                        <td><strong>F. Nacimiento :</strong> {{ usuario.fecha_nacimiento | formatDate }}
                                        </td>
                                        <td><strong>Sexo :</strong> {{
                                            usuario.sexo === 'M' ? 'MASCULINO' : 'FEMENINO'
                                        }}
                                        </td>
                                        <td><strong>Edad :</strong> {{ usuario.years }}</td>
                                        <td><strong>Nivel :</strong> {{ usuario.nivel }}</td>
                                    </tr>
                                    <tr>
                                        <td colspan="4">
                                            <strong>EPS :</strong> ({{ eps.IDEPS }}) {{ eps.DESEPS }}
                                        </td>
                                    </tr>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="mt-2 card shadow-none">

                <div class="card-body">
                    
                    <ul class="nav nav-tabs mb-3" id="pills-tab" role="tablist">
                        <li class="nav-item" role="presentation" v-for="(report, index) in reports" :key="index">
                            <a  class="nav-link" id="pills-home-tab"
                                data-toggle="pill" href="#pills-home" role="tab" aria-controls="pills-home"
                                aria-selected="true" :class="{ 'active': report.component === selectedReport }"
                                @click="selectReport(report.component)">{{ report.name }}</a>
                        </li>
                    </ul>
                    <div class="tab-content" id="pills-tabContent">
                        <div class="tab-pane fade show active" id="pills-home" role="tabpanel"
                            aria-labelledby="pills-home-tab">
                            <component v-bind:is="selectedReport" :usuario="usuario" :eps="eps"></component>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </main>
</template>
<script>

import UsuarioField from "../../../../components/common/UsuarioField";
import { required } from "vuelidate/lib/validators";
import PageHeaderCompact from "../../../../components/layouts/content/page-header-compact";
const createComponent = () => ({
    component: import('./createComponent.vue')
});

const listComponent = () => ({
    component: import('./listComponent.vue')
})

export default {
    components: {
        UsuarioField, PageHeaderCompact
    },
    data() {
        return {
            reports: [
                {
                    name: "Registro de Pruebas",
                    icon: 'fa fa-female fa-fw',
                    component: createComponent,
                },
                {
                    name: "Listado de Pruebas",
                    icon: 'fa fa-female fa-fw',
                    component: listComponent,
                }
            ],
            selectedReport: createComponent,
            usuario: {},
            eps: {},
            fecha: '',
        };
    },
    validations() {
        return {
            usuario: { required }
        }
    },
    methods: {
        selectReport(report) {
            this.selectedReport = report;
        },

    },
    watch: {
        usuario: {
            handler: function () {
                this.eps = this.usuario.contratos[0] ? this.usuario.contratos[0].contrato.eps : '';
            },
            deep: true
        }
    },

}
</script>